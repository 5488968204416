<template>
  <PageSection :id="componentId">
    <div v-if="data?.data?.length">
      <KInput
        v-if="enableSearch"
        v-model.trim="searchName"
        class="apis-search-input"
        :placeholder="t('apis.search_placeholder')"
        type="search"
      >
        <template #before>
          <FilterIcon />
        </template>
        <template #after>
          <button
            v-if="searchName"
            :aria-label="t('actions.clear_search')"
            :title="t('actions.clear_search')"
            type="button"
            @click="searchName = ''"
          >
            <CloseIcon decorative />
          </button>
        </template>
      </KInput>

      <PageContainer
        class="apis-list"
        display="grid"
        :grid-columns-breakpoints="gridColumnsBreakpoints"
      >
        <ApisCard
          v-for="product in data.data"
          :key="product.source.id"
          :api-description="product.source.description || ''"
          :api-id="product.source.id"
          :api-name="product.source.name || ''"
          class="api-card"
          data-testid="api-card"
        >
          <template #actions>
            <slot
              :api="product"
              name="actions"
            />
          </template>

          <template #error>
            <slot
              :api="product"
              name="error"
            />
          </template>

          <template #footer-left>
            <slot
              :api="product"
              name="footer-left"
            >
              <KBadge>
                {{ t('apis.version', { count: product.source.version_count }) }}
              </KBadge>
            </slot>
          </template>

          <template #footer-right>
            <slot
              :api="product"
              name="footer-right"
            >
              <div class="api-document-buttons">
                <KButton
                  v-if="product.source.latest_version?.id"
                  appearance="secondary"
                  :to="{ path: `/products/${product.source.id}/versions/${product.source.latest_version.id}/specs` }"
                >
                  {{ t('labels.specs') }}
                </KButton>
                <KButton
                  v-if="product.source.document_count"
                  appearance="secondary"
                  :to="{ path: `/products/${product.source.id}/docs` }"
                >
                  {{ t('labels.docs') }}
                </KButton>
              </div>
            </slot>
          </template>
        </ApisCard>
      </PageContainer>

      <KPagination
        v-if="paginationPageSizes[0] < Number(data?.meta?.page?.total || 0)"
        :current-page="currentPageNumber"
        data-testid="api-pagination"
        :page-sizes="paginationPageSizes"
        :total-count="data.meta.page.total"
        @page-change="setPage"
        @page-size-change="setPageSize"
      />
    </div>

    <EmptyState
      v-else-if="error"
      :description="error.statusMessage ? t('errors.apis.fetch') : undefined"
      :title="error.statusMessage || t('errors.apis.fetch')"
    >
      <template #icon>
        <WarningIcon
          :color="`var(--kui-icon-color-danger, ${KUI_ICON_COLOR_DANGER})`"
          decorative
        />
      </template>
    </EmptyState>
    <EmptyState
      v-else-if="status !== 'pending'"
      :description="t('errors.apis.no_results')"
      title="Welcome to your API Catalog"
    >
      <template #icon>
        <ConnectionsIcon decorative />
      </template>
    </EmptyState>
  </PageSection>
</template>

<script setup lang="ts">
import { CloseIcon, ConnectionsIcon, FilterIcon, WarningIcon } from '@kong/icons'
import { KUI_ICON_COLOR_DANGER } from '@kong/design-tokens'
import type { PageChangeData, PageSizeChangeData } from '@kong/kongponents'
import { watchDebounced } from '@vueuse/core'

const props = defineProps({
  /** Enables search by API name */
  enableSearch:{
    type: Boolean,
    required: false,
    default: false,
  },
  /** The current page number */
  pageNumber: {
    type: Number,
    required: false,
    default: undefined,
  },
  /** When enabled, the pagination page number will be persisted in the `page` URL query param */
  persistPageNumber: {
    type: Boolean,
    default: false,
  },
  /** The number of entities per page */
  pageSize: {
    type: Number,
    required: false,
    default: undefined,
  },
  /** The pagination per-page options */
  pageSizes: {
    type: Array as PropType<number[]>,
    default: () => [],
  },
  /** Grid columns breakpoints. Accepts an Object of interface Breakpoints */
  gridColumnsBreakpoints: {
    type: Object as PropType<Breakpoints>,
    required: false,
    default: () => ({
      mobile: 2,
      phablet: 3,
      tablet: 3,
      desktop: 4,
    }),
  },
  /** Provide custom CSS rules, scoped to this component */
  styles: {
    type: String,
    required: false,
    default: '',
  },
})

// Inject any custom `props.styles` scoped by the `componentId` into the document head
const { componentId } = useCustomStyles(computed(() => props.styles), useAttrs().id as string)

const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const apisListId = `apis-list-${useId()!}`

// Compute the initial page number based on route query or props
const currentPageNumber = useState<number>(`page-number-${apisListId}`, () => {
  if (typeof props.pageNumber === 'number' && props.pageNumber > 0) {
    return props.pageNumber
  }
  if (props.persistPageNumber && !isNaN(Number(route.query.page)) && Number(route.query.page) > 0) {
    return Number(route.query.page)
  }

  // Default to the first page
  return 1
})
const paginationPageSizes = useState<number[]>(`page-sizes-${apisListId}`, () => props.pageSizes.length ? props.pageSizes : [24, 36, 48, 60])
const currentPageSize = useState<number>(`current-page-size-${apisListId}`, () => props.pageSize || paginationPageSizes.value[0])

const setPage = (event: PageChangeData) => {
  currentPageNumber.value = event.page
}
const setPageSize = (event: PageSizeChangeData) => {
  currentPageSize.value = event.pageSize
}

const searchName = useState<string>(`search-name-${apisListId}`, () => '')
const nameQuery = useState<string>(`name-query-${apisListId}`, () => '')

watchDebounced(searchName, (val) => {
  nameQuery.value = val
  currentPageNumber.value = 1
}, {
  debounce: 1000,
})

const clearData = ():void => {
  searchName.value = ''
  nameQuery.value = ''
  currentPageNumber.value = 1
}

const { transform, getCachedData } = serveCachedData()
const { data, status, error } = await usePortalApi('/api/v2/search/{indices}', {
  path: {
    indices: 'product-catalog',
  },
  query: {
    join: 'versions',
    // @ts-ignore: nuxt-open-fetch query interface is not correctly typed for reactive values
    q: nameQuery ? nameQuery : undefined,
    // @ts-ignore: nuxt-open-fetch query interface is not correctly typed for reactive values
    'page[number]': currentPageNumber,
    // @ts-ignore: nuxt-open-fetch query interface is not correctly typed for reactive values
    'page[size]': currentPageSize,
  },
  // Serve cached data if exists and not expired
  ...(!props.enableSearch ? [transform,
    getCachedData] : []),
})

// Did the component already retry resetting the page number on 400 error? (init as false)
const didRetryRestPageNumber = useState<boolean>(`did-retry-${apisListId}`, () => false)

// Watch the error object
watch(error, (err) => {
  if (import.meta.client) {

    // If the API returns a 400 error, it's likely due to an invalid page number, so reset to 1
    if (!didRetryRestPageNumber.value && err && (err.statusCode === 400 || String(err.data?.detail || '').toLowerCase().includes('page offset'))) {
      // Prevent an additional automatic retry
      didRetryRestPageNumber.value = true
      // Reset the page number to 1
      currentPageNumber.value = 1
    }
  }
}, { immediate: true })

// Update the route.query.page when the currentPageNumber changes
watch(currentPageNumber, (page) => {
  // If persistPageNumber is disabled, exit early
  if (!props.persistPageNumber) {
    return
  }

  // If the page number is less than 2, remove the query parameter
  if (!page || page < 2) {
    router.push({
      query: {
        // Keep other query params
        ...route.query,
        // Remove page query param
        ...{ page: undefined },
      },
    })
    return
  }

  // Add the page number query parameter
  router.push({
    query: {
      ...route.query,
      page,
    },
  })
}, { immediate: true })

onBeforeUnmount(async () => {
  clearData()
})
</script>

<style lang="scss" scoped>
.apis {
  &-list {
    margin: var(--kui-space-70, $kui-space-70) var(--kui-space-0, $kui-space-0);

    :deep(.api-card) {
      min-height: unset;
    }
  }

  &-search-input {
    :deep(input[type="search"]) {
      &::-webkit-search-cancel-button {
        display: none;
      }
    }
  }
}

.api-card-content {
  display: flex;
  flex-direction: column;
  gap: var(--kui-space-70, $kui-space-70);
  height: 100%;
  justify-content: space-between;

  h1, h2, h3, h4, h5, p:first-of-type {
    margin-top: var(--kui-space-0, $kui-space-0);
  }
}

.api-card-footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.api-document-buttons {
  display: flex;
  gap: var(--kui-space-40, $kui-space-40);
}
</style>
